<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="background: #fafafa">
        <div class="text-center">
            <v-dialog
                    v-model="dialog"
                    persistent
                    width="50"
            >
                <img src='../../assets/loader_spinner.gif' style="width: 50px; ">

            </v-dialog>
        </div>
        <v-row>
            <v-col>
                <v-container class="my-8 px-3">
                    <v-row class="px-3">

                        <v-col cols="0" md="2"></v-col>
                        <v-col cols="12" md="8">

                            <v-row>
                                <v-col cols="12" class="hidden-sm-and-down">
                                    <ul class="resvDateRangeBox">
                                        <li class="">
                                            <div>
                                                <span class="resvDateRangeSub">체크인</span>
                                                <template v-if="dates.startDate == '-'">
                                                    -
                                                </template>
                                                <template v-else>
                                                    {{dates.startDate}}({{dates.startDow}})
                                                </template>

                                            </div>
                                        </li>
                                        <li class="resvDateRangeBox_dateRage">
                                            {{dates.dateRange}}
                                        </li>
                                        <li class="">
                                            <div>
                                                <span class="resvDateRangeSub">체크아웃</span>
                                                <template v-if="dates.startDate == '-'">
                                                    -
                                                </template>
                                                <template v-else>
                                                    {{dates.endDate}}({{dates.endDow}})
                                                </template>

                                            </div>
                                        </li>
                                    </ul>
                                </v-col>
                                <v-col cols="12" class="hidden-md-and-up">
                                    <table class="resvDateRangeTable">
                                        <tr>
                                            <td>
                                                <div class="resvDateRangeSub">체크인</div>
                                                <div v-if="dates.startDate == '-'">
                                                    -
                                                </div>
                                                <div v-else>
                                                    {{dates.startDate}}({{dates.startDow}})
                                                </div>
                                            </td>
                                            <td class="resvDateRangeTable_dateRage">
                                                {{dates.dateRange}}
                                            </td>
                                            <td>
                                                <div class="resvDateRangeSub">체크아웃</div>
                                                <div v-if="dates.startDate == '-'">
                                                    -
                                                </div>
                                                <div v-else>
                                                    {{dates.endDate}}({{dates.endDow}})
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" class="sub-title-3 mt-4">
                                    예약내용
                                    <v-btn
                                            class="mx-4 white--text center-block float-right"
                                            style="background-color: red;"
                                            color="error"
                                            small
                                            @click="addResv"
                                    >
                                        다른 상품 예약추가
                                    </v-btn>
                                </v-col>
                                <template v-if="!isMobile">
                                    <table class="table-top-s table-top-s-ts">
                                        <colgroup>

                                            <col style="width:25%"/>
                                            <col style="width:20%"/>
                                            <col style="width:20%"/>
                                            <col style="width:20%"/>
                                            <col style="width:15%"/>
                                        </colgroup>
                                        <tr>

                                            <th @click="sortTable" style="cursor: pointer">객실명</th>
                                            <th>기준/최대</th>
                                            <th>예약인원</th>
                                            <th class="num-right">이용요금</th>
                                            <th>삭제</th>
                                        </tr>
                                        <tr
                                                v-for="(item,i) in this.resvConfirmList"
                                                :key="i">
                                            <td>{{item.roomNm}}</td>
                                            <td>{{item.stdNop}}명/{{item.maxNop}}명</td>
                                            <td>{{item.resvNop}}명</td>
                                            <td class="num-right">{{item.totalPrice | comma}}원</td>
                                            <td>
                                                <v-btn
                                                        icon
                                                        x-small
                                                        color="pink"
                                                        @click="delRoom(item)"
                                                >
                                                    <v-icon>mdi-close</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>전체</td>
                                            <td></td>
                                            <td></td>
                                            <td class="num-right">{{resvConfirmInfo.resvPrice | comma}}원</td>
                                            <td></td>
                                        </tr>
                                    </table>
                                </template>
                                <template v-else>
                                    <table class="table-top-s" style="border-top:2px solid gray;">
                                        <colgroup>

                                            <col style="width:40%"/>
                                            <col style="width:20%"/>
                                            <col style="width:33%"/>
                                            <col style="width:7%"/>
                                        </colgroup>
                                        <tr>
                                            <th class="pl-4">객실</th>
                                            <th>인원</th>
                                            <th class="num-right">요금</th>
                                            <th></th>
                                        </tr>
                                        <tr
                                                v-for="(item,i) in this.resvConfirmList"
                                                :key="i">
                                            <td>{{item.roomNm}} <br> <span style="font-size: 0.7rem; color: gray;">{{item.roomForm}}</span>
                                            <td>{{item.resvNop}}명</td>
                                            <td class="num-right">{{item.totalPrice | comma}}원</td>
                                            <td>
                                                <v-btn
                                                        icon
                                                        x-small
                                                        color="pink"
                                                        @click="delRoom(item)"
                                                >
                                                    <v-icon>mdi-close</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                        <tr style="height: 50px;">
                                            <td>전체금액</td>
                                            <td></td>
                                            <td class="num-right">{{resvConfirmInfo.resvPrice | comma}}원</td>
                                            <td></td>
                                        </tr>
                                    </table>
                                </template>
                            </v-row>
                            <v-row v-if="isCampItem">
                                <v-col cols="12" class="sub-title-3 mt-4">
                                    추가요금
                                </v-col>
                                <template v-if="!isMobile">
                                    <table class="table-top-s">
                                        <colgroup>
                                            <col style="width:40%"/>
                                            <col style="width:15%"/>
                                            <col style="width:15%"/>
                                            <col style="width:20%"/>
                                        </colgroup>
                                        <tr>
                                            <th class="pl-4">해당내역</th>
                                            <th>단가</th>
                                            <th>수량</th>
                                            <th class="num-right">이용요금</th>
                                        </tr>
                                        <tr
                                                v-for="(item,i) in this.campItem"
                                                :key="i">
                                            <td class="pl-4">{{item.campName}}</td>
                                            <td>{{item.campPrice | comma}}원</td>
                                            <td>
                                                <select v-model="item.campQty" @change="selectChange($event, item)">
                                                    <option v-for="(n, i) in 5" :value="i">{{i}}</option>
                                                </select>
                                            </td>
                                            <td class="num-right">{{item.campPrices | comma}}원</td>
                                        </tr>
                                    </table>
                                </template>
                                <template v-else>
                                    <table class="table-top-s">
                                        <colgroup>
                                            <col style="width:45%"/>
                                            <col style="width:15%"/>
                                            <col style="width:40%"/>
                                        </colgroup>
                                        <tr>
                                            <th class="pl-4">해당내역</th>
                                            <th>수량</th>
                                            <th class="num-right">요금</th>
                                        </tr>
                                        <tr
                                                v-for="(item,i) in this.campItem"
                                                :key="i">
                                            <td class="pl-4">{{item.campName}} <br> <span style="color: #1976d2 ;">{{item.campPrice | comma}}원 </span>
                                            </td>
                                            <td>
                                                <select v-model="item.campQty" @change="selectChange($event, item)">
                                                    <option v-for="(n, i) in 5" :value="i">{{i}}</option>
                                                </select>
                                            </td>
                                            <td class="num-right">{{item.campPrices | comma}}원</td>
                                        </tr>
                                    </table>
                                </template>

                                <v-col cols="12"
                                       style="font-size: 1rem; padding-top:3px; color:darkblue; font-weight: bold;">
                                    ☆ 캠핑 고객님만 해당 시에 선택해 주세요! <br>
                                    <!--☆ 캠핑카나 트레일러를 가져 오시는 분만 선택해 주세요-->
                                    ☆ 요금은 '수량 x 숙박일수' 로 산정 됨니다.
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" class="sub-title-3 mt-2">
                                    예약자 정보
                                </v-col>
                                <template v-if="isMobile">
                                    <table class="table-left-s mobile">
                                        <colgroup>
                                            <col style="width:30%"/>
                                            <col style="width:70%"/>
                                        </colgroup>
                                        <tr>
                                            <th class="th" style="color:red; font-size:0.9rem;">* 예약자명</th>
                                            <td class="td">
                                                <input v-model="resvConfirmInfo.resvName" style="width: 100%;"
                                                       placeholder="정확히 입력해주세요.">
                                            </td>
                                        </tr>
                                        <tr v-if="payInfo.isPw">
                                            <th class="th" style="color:red; font-size:0.9rem;">* 비밀번호</th>
                                            <td class="td">
                                                <input type="password" v-model="resvConfirmInfo.resvPw"
                                                       style="width: 100%;">
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th" style="color:red; font-size:0.9rem;">* 연락번호</th>
                                            <td class="td">
                                                <input v-model="resvConfirmInfo.resvPhone" style="width: 100%;"
                                                       placeholder="- 없이 숫자만 입력"
                                                       maxlength="13"
                                                       @keyup="autoHyphen($event.target)">
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th" style="font-size:0.9rem;">남기실말씀</th>
                                            <td class="td">
                                            <textarea v-model="resvConfirmInfo.resvMemo" style="resize: none;"
                                                      id="textarea1"
                                                      placeholder="남기실 말씀을 적어 주세요."
                                                      @input="input1($event.target.value)">></textarea>
                                            </td>
                                        </tr>
                                    </table>
                                </template>
                                <template v-else>
                                    <table class="table-left-s">
                                        <colgroup>
                                            <col style="width:30%"/>
                                            <col style="width:70%"/>
                                        </colgroup>
                                        <tr>
                                            <th class="th" style="color:red;">* 예약자명</th>
                                            <td class="td">
                                                <input v-model="resvConfirmInfo.resvName"
                                                       placeholder="정확히 입력해주세요.">

                                            </td>
                                        </tr>
                                        <tr v-if="payInfo.isPw">
                                            <th class="th" style="color:red;">* 비밀번호</th>
                                            <td class="td">
                                                <input type="password" id="resvPw" v-model="resvConfirmInfo.resvPw"
                                                       style="">

                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th" style="color:red;">* 연락번호</th>
                                            <td>
                                                <input v-model="resvConfirmInfo.resvPhone" style="width: 100%;"
                                                       placeholder="- 없이 숫자만 입력"
                                                       maxlength="13"
                                                       @keypress="autoHyphen($event.target)">
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">남기실말씀</th>
                                            <td class="td">
                                            <textarea v-model="resvConfirmInfo.resvMemo" style="resize: none;"
                                                      id="textarea"
                                                      placeholder="남기실 말씀을 적어 주세요."
                                                      @input="input($event.target.value)">></textarea>
                                            </td>
                                        </tr>
                                    </table>
                                </template>
                            </v-row>

                            <v-row>
                                <v-col cols="12" class="sub-title-3 mt-4">
                                    결제방식
                                </v-col>
                                <template v-if="isMobile">
                                    <table class="table-left-s mobile">
                                        <colgroup>
                                            <col style="width:30%"/>
                                            <col style="width:70%"/>
                                        </colgroup>
                                        <tr>
                                            <th class="th">결제금액</th>
                                            <td class="td red--text" style="font-size: 16px; padding:5px;">
                                                {{resvConfirmInfo.totalPrice | comma}}원
                                            </td>

                                        </tr>
                                        <tr>
                                            <th class="th">결제수단</th>
                                            <td class="td">
                                                <template v-if="payInfo.pgMethod.isMethod0">
                                                    <input type="radio" id="pay1" value="0" v-model="payType"
                                                           style="width: 20px; height: 12px;">
                                                    <label for="pay1">무통장입금</label>
                                                </template>
                                                <template v-if="payInfo.pgMethod.isMethod1">
                                                    <input type="radio" id="pay4" value="1" v-model="payType"
                                                           style="width: 20px; height: 12px;">
                                                    <label for="pay4">가상계좌</label>
                                                </template>
                                                <template v-if="payInfo.pgMethod.isMethod2">
                                                    <input type="radio" id="pay2" value="2" v-model="payType"
                                                           style="width: 20px; height: 12px;">
                                                    <label for="pay4">카드</label>
                                                </template>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th" style="color:red;">* 입금자명</th>
                                            <td class="td" style="">
                                                <div style="width: 100%; display: flex; flex-wrap : wrap;">
                                                    <div>
                                                        <input v-model="resvConfirmInfo.payName"
                                                               placeholder="정확히 입력해주세요.">
                                                    </div>
                                                    <div style="display: block; padding-top:5px;">
                                                        <input type="checkbox" id="checkbox1" v-model="isCheckPayName"
                                                               class="checkbox-custom-admin"
                                                               @change="checkPayName($event)">
                                                        <label for="checkbox1" class="label-custom-admin"
                                                               style="color:red;">예약자와 동일</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </template>
                                <template v-else>
                                    <table class="table-left-s">
                                        <colgroup>
                                            <col style="width:30%"/>
                                            <col style="width:70%"/>
                                        </colgroup>
                                        <tr>
                                            <th class="th">결제금액</th>
                                            <td class="td red--text" style="font-size: 16px; padding:5px;">
                                                {{resvConfirmInfo.totalPrice | comma}}원
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th">결제수단</th>
                                            <td class="td">
                                                <template v-if="payInfo.pgMethod.isMethod0">
                                                    <input type="radio" id="pay1" value="0" v-model="payType"
                                                           style="width: 20px; height: 12px;">
                                                    <label for="pay1">무통장입금</label>
                                                </template>
                                                <template v-if="payInfo.pgMethod.isMethod1">
                                                    <input type="radio" id="pay4" value="1" v-model="payType"
                                                           style="width: 20px; height: 12px;">
                                                    <label for="pay4">가상계좌</label>
                                                </template>
                                                <template v-if="payInfo.pgMethod.isMethod2">
                                                    <input type="radio" id="pay2" value="2" v-model="payType"
                                                           style="width: 20px; height: 12px;">
                                                    <label for="pay4">카드</label>
                                                </template>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th" style="color:red;">* 입금자명</th>
                                            <td class="td" style="">
                                                <div style="width: 100%; display: flex; flex-wrap : wrap;">
                                                    <div>
                                                        <input v-model="resvConfirmInfo.payName"
                                                               placeholder="정확히 입력해주세요.">
                                                    </div>
                                                    <div style="display: block; padding-top:5px;">
                                                        <input type="checkbox" id="checkbox1" v-model="isCheckPayName"
                                                               class="checkbox-custom-admin"
                                                               @change="checkPayName($event)">
                                                        <label for="checkbox1" class="label-custom-admin"
                                                               style="color:red; font-size:0.9rem;">예약자와 동일</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </template>

                                <v-col cols="12"
                                       style="font-size: 1.2rem; padding-top:3px;color:darkblue;font-weight: bold;">
                                    ※유의사항 및 환불규정 등은 이용안내를 확인해주세요!
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="hidden-sm-and-down">
                                    <ul style="" class="roundBtn calc-4">
                                        <li></li>
                                        <li class="roundBtn-blue-li">
                                            <a @click="revPayBtnClick(false)">취소</a>
                                        </li>
                                        <li class="roundBtn-red-li">
                                            <a @click="revPayBtnClick(true)">예약하기</a>
                                        </li>
                                        <li></li>
                                    </ul>
                                </v-col>
                                <v-col cols="12" class="hidden-md-and-up">
                                    <ul style="width:100%; margin:0 auto !important;" class="roundBtn calc-2">
                                        <li class="roundBtn-blue-li">
                                            <a @click="revPayBtnClick(false)">취소</a>
                                        </li>
                                        <li class="roundBtn-red-li">
                                            <a @click="revPayBtnClick(true)">예약하기</a>
                                        </li>
                                    </ul>
                                </v-col>
                            </v-row>

                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: 'ReservationPaymentTmp',
        components: {},
        data: () => ({
            isMobile: false,
            dialog: false,
            resvConfirmInfo: {
                roomData: []
            },
            resvConfirmList: [],
            resvSelectData: {},
            payType: "0",
            payAccountNum: "농협 352-0170-6948-03 예금주 최정자",
            campItem: [
                {
                    isCheck: false,
                    campName: "캠핑카",
                    campPrice: 10000,
                    campQty: 0,
                    campUnit: "1"

                }
            ],
            isCampItem: true,
            autoresize: {
                type: [Boolean, String],
                default: false
            },
            clientKey: 'test_ck_Kma60RZblrqG9p6aNNb3wzYWBn14',
            payInfo: {
                pgMethod: {
                    isMethod0: false,
                    isMethod1: false,
                    isMethod2: false,
                    isMethod3: false,
                },
                pgNm: "",
                pgId: "",
                isPw: false,
            },
            dates: {startDate: "", startDow: "", endDate: "", endDow: "", dateRange: ""},
            isSort: false,
            isDirectPhone: false,
            isCheckPayName: false,
            textBasics: "",
            textRefund: "",
            textNotice: "",

        }),
        created: function () {

            // 모바일 체크
            this.isMobile = this.$isMobile();

            // 맨위로
            window.scrollTo(0, 0);
            this.dates.startDate = this.$getParameterByName('std');
            this.dates.endDate = this.$getParameterByName('edd');
            let isAdd = this.$getParameterByName('isAdd');

            if (this.dates.startDate === undefined || this.dates.startDate.length < 5) {
                alert("잘못된 요청입니다.");
                //window.history.back();
                this.$router.push({path: "/reservation/search"});
                return;
            }

            if (isAdd === 'true') {

                this.getResvUsrInfo();
            } else {
                this.$store.commit("reservation/CLEAR_RESV_USR_INFO");
            }

            // 날짜
            this.getDates();

            // 가격
            this.setPrice();

            // 캥핑아이템템
            for (let i = 0; this.resvConfirmList.length > i; i++) {
                if (this.resvConfirmList[i].roomGubun != undefined && this.resvConfirmList[i].roomGubun === "R") {
                    this.isCampItem = false;
                } else {
                    this.isCampItem = true;

                    // 캠핑 아이템 가져오기
                    this.getCampItem();
                    break;
                }

            }
            // 결제 정보 가져오기
            this.$store.dispatch("admin/getComn", {comnGubun: 'payInfo'})
                .then((resp) => {
                    setTimeout(() => {

                        if (resp.message !== undefined && resp.message.comnCont !== undefined) {
                            this.payInfo = JSON.parse(resp.message.comnCont);
                        }

                    }, 300)
                })
                .catch((err) => {

                })

        },
        methods: {
            setPrice() {

                this.resvConfirmList = JSON.parse(JSON.stringify(this.$store.state.reservation.resvConfirmList));
                this.resvConfirmInfo.phone1 = "010";

                let fullPrice = 0;

                if (this.resvConfirmList.length <= 0) {

                    alert("잘못된 요청입니다.!!!!");
                    this.$router.push({path: "/reservation/search"});
                    //window.history.back();
                    return;
                }

                // 합계
                for (let i = 0; this.resvConfirmList.length > i; i++) {
                    fullPrice += this.resvConfirmList[i].totalPrice;
                }

                this.resvConfirmInfo.resvPrice = fullPrice;
                this.resvConfirmInfo.totalPrice = fullPrice;


            },
            getDates() {

                let std = new Date(this.dates.startDate);
                let end = new Date(this.dates.endDate);
                let dateRange = Math.ceil((end.getTime() - std.getTime()) / (1000 * 3600 * 24));
                let week = ['일', '월', '화', '수', '목', '금', '토'];

                this.dates.startDow = week[new Date(this.dates.startDate).getDay()];
                this.dates.endDow = week[new Date(this.dates.endDate).getDay()];

                this.dates.dateRange = dateRange + "박 / " + (dateRange + 1) + "일";

                if (this.dates.startDate === this.dates.endDate && this.dates.startDate.length > 0) {
                    alert("날짜를 2일 이상 선택해 주세요");
                }

            },
            getUseInfo() {

                // 공통 정보 가져오기
                return this.$store.dispatch("admin/getComn", {comnGubuns: ['textNotice', 'textRefund', 'textBasics']})
                    .then((resp) => {

                        if (resp.message === undefined) {
                            alert("잘못된 요청입니다.");
                            window.history.back();
                            return;
                        }

                        this.comnList = resp.message;

                        let _this = this;

                        this.comnList.forEach(function (e) {

                            switch (e.comnGubun) {
                                case 'textNotice': {
                                    _this.textNotice = e.comnCont;

                                    break;
                                }
                                case 'textRefund': {
                                    _this.textRefund = e.comnCont;
                                    break;
                                }
                                case 'textBasics': {
                                    _this.textBasics = e.comnCont;
                                    break;
                                }
                            }


                        });

                    })
                    .catch((err) => {
                    })
            },
            getResvUsrInfo() {   // 입력데이터 있나없나 확인

                //let resvUsrInfo = JSON.parse(JSON.stringify(this.$store.state.reservation.resvUsrInfo));
                let resvUsrInfo = this.$store.state.reservation.resvUsrInfo;
                this.resvConfirmInfo.payName = resvUsrInfo.payName;
                //this.resvConfirmInfo.phone1 = resvUsrInfo.phone1;
                //this.resvConfirmInfo.phone2 = resvUsrInfo.phone2;
                //this.resvConfirmInfo.phone3 = resvUsrInfo.phone3;

                if (resvUsrInfo.resvPhone != undefined && resvUsrInfo.resvPhone.length > 0) {
                    this.resvConfirmInfo.resvPhone = this.$store.state.reservation.resvUsrInfo.resvPhone;
                }


                this.resvConfirmInfo.resvMemo = resvUsrInfo.resvMemo;
                this.resvConfirmInfo.resvName = resvUsrInfo.resvName;

            },
            sortTable() {    // 테이블 정렬
                if (this.isSort) {
                    this.resvConfirmList.sort((a, b) => a.roomId.toLowerCase() > b.roomId.toLowerCase() ? -1 : 1);
                } else {
                    this.resvConfirmList.sort((a, b) => a.roomId.toLowerCase() < b.roomId.toLowerCase() ? -1 : 1);
                }

                this.isSort = !this.isSort;

            },
            getCampItem() { // 캠핑아이템 가져오기기
                this.$store.dispatch("reservation/getCampItem", {useYn: "Y"})
                    .then((resp) => {
                        let tmp_campItem = resp.message;
                        for (let i = 0; tmp_campItem.length > i; i++) {
                            tmp_campItem[i]["isCheck"] = false;
                            tmp_campItem[i]["campQty"] = 0;
                            tmp_campItem[i].campPrice = parseInt(tmp_campItem[i]["campPrice"]);
                            //tmp_campItem[i].campPrices = parseInt(tmp_campItem[i]["campPrice"]);
                            tmp_campItem[i].campPrices = 0;

                        }
                        this.campItem = tmp_campItem;

                    })
                    .catch((err) => {
                    })
            },
            preventNav(event) {
                event.preventDefault();
                event.returnValue = ""
            },
            addResv() {

                let usrInfo = {
                    payName: this.resvConfirmInfo.payName,
                    phone1: this.resvConfirmInfo.phone1,
                    phone2: this.resvConfirmInfo.phone2,
                    phone3: this.resvConfirmInfo.phone3,
                    resvPhone: this.resvConfirmInfo.resvPhone,
                    resvMemo: this.resvConfirmInfo.resvMemo,
                    resvName: this.resvConfirmInfo.resvName,
                };

                this.$store.commit("reservation/SAVE_RESV_USR_INFO", usrInfo);

                location.href = "/reservation/searchAdd" + '?std=' + this.dates.startDate + '&edd=' + this.dates.endDate;

            },
            delRoom(item) {

                if (!confirm("항목을 삭제 하시겠습니까?")) {
                    // 취소(아니오) 버튼 클릭 시 이벤트
                } else {
                    // 확인(예) 버튼 클릭 시 이벤트
                    if (item.roomId != undefined) {
                        for (let i = 0; this.resvConfirmList.length > i; i++) {
                            if (this.resvConfirmList[i].roomId === item.roomId) {
                                this.resvConfirmList.splice(i, 1);

                                this.$store.commit("reservation/SAVE_RESV_CONFIRM", this.resvConfirmList);
                                this.setPrice();
                            }
                        }
                    } else {

                    }
                }

            },
            revPayBtnClick(val) {

                if (!val) {   // 취소
                    //location.href = '/reservation/search';
                    this.$router.push({path: "/reservation/search"});

                } else {  // 다음단계
                    this.dialog = true;

                    this.resvConfirmInfo.campItem = [];

                    if (this.isCampItem) {
                        for (let i = 0; this.campItem.length > i; i++) {

                            if (this.campItem[i].isCheck) {
                                this.resvConfirmInfo.campItem.push(this.campItem[i]);
                            }
                        }
                    }

                    if (this.resvConfirmList.length <= 0) {

                        alert("잘못된 요청입니다.");

                        this.$router.push({path: "/reservation/search"});
                        return;

                    }

                    for (let i = 0; this.resvConfirmList.length > i; i++) {

                        this.resvConfirmList[i].resvStartDate = this.dates.startDate;
                        this.resvConfirmList[i].resvEndDate = this.dates.endDate;

                        this.resvConfirmList[i].resvDateRange = this.dates.dateRange;
                        this.resvConfirmList[i].resvPrice = this.resvConfirmList[i].totalPrice;
                        this.resvConfirmList[i].lstModPrs = this.resvConfirmInfo.resvName;

                    }

                    this.resvConfirmInfo.resvGubun = "1";


                    if (this.resvConfirmInfo.resvName === undefined || this.resvConfirmInfo.resvName.length === 0) {
                        this.dialog = false;
                        alert("예약자명을 입력해 주세요.");
                        return;
                    } else {
                        this.resvConfirmInfo.lstModPrs = this.resvConfirmInfo.resvName;
                    }

                    if (this.payInfo.isPw) {
                        if (this.resvConfirmInfo.resvPw === undefined || this.resvConfirmInfo.resvPw.length === 0) {
                            this.dialog = false;
                            alert("비밀번호를 입력해 주세요.");
                            return;
                        }
                    }

                    /*if (this.isDirectPhone) {

                        if (this.resvConfirmInfo.phone11 == undefined || this.resvConfirmInfo.phone2 == undefined || this.resvConfirmInfo.phone3 == undefined) {
                            this.dialog = false;
                            alert("전화번호 입력해 주세요.");
                            return;
                        } else if (this.resvConfirmInfo.phone11.length === 0 || this.resvConfirmInfo.phone2.length === 0 || this.resvConfirmInfo.phone3.length === 0) {
                            this.dialog = false;
                            alert("전화번호 입력해 주세요.");
                            return;
                        } else {
                            //this.resvConfirmInfo.phone1 = this.resvConfirmInfo.phone11;
                            this.resvConfirmInfo.resvPhone = this.resvConfirmInfo.phone11 + "-" + this.resvConfirmInfo.phone2 + "-" + this.resvConfirmInfo.phone3;
                        }
                    } else {

                        if (this.resvConfirmInfo.phone1 == undefined || this.resvConfirmInfo.phone2 == undefined || this.resvConfirmInfo.phone3 == undefined) {
                            this.dialog = false;
                            alert("전화번호 입력해 주세요.");
                            return;
                        } else if (this.resvConfirmInfo.phone1.length === 0 || this.resvConfirmInfo.phone2.length === 0 || this.resvConfirmInfo.phone3.length === 0) {
                            this.dialog = false;
                            alert("전화번호 입력해 주세요.");
                            return;
                        } else {
                            this.resvConfirmInfo.resvPhone = this.resvConfirmInfo.phone1 + "-" + this.resvConfirmInfo.phone2 + "-" + this.resvConfirmInfo.phone3;
                        }
                    }*/


                    if (this.resvConfirmInfo.payName === undefined || this.resvConfirmInfo.payName.length === 0) {
                        this.dialog = false;
                        alert("입금자명을 입력해 주세요.")
                        return;
                    }

                    if (this.resvConfirmInfo.resvPhone === undefined || this.resvConfirmInfo.resvPhone.length === 0) {
                        this.dialog = false;
                        alert("전화번호를 입력해 주세요.");
                        return;
                    } else {

                        if (!this.$isHpFormatH(this.resvConfirmInfo.resvPhone)) {
                            this.dialog = false;
                            alert("전화번호를 정확히 입력해 주세요.");
                            return;
                        } else {
                            // 핸드폰 번호 변경
                            this.resvConfirmInfo.resvPhone = this.$delPhoneHyphen(this.resvConfirmInfo.resvPhone);

                        }
                    }

                    this.resvConfirmInfo.roomData = this.resvConfirmList;

                    // 예약일 설정
                    //this.resvConfirmInfo.resvDate = dateString;
                    this.resvConfirmInfo.resvDate = this.dates.startDate;

                    // 예약방이름
                    let resvRoomSize = this.resvConfirmInfo.roomData.length;
                    let resvRoomNm = '';
                    if (resvRoomSize > 1) {
                        resvRoomNm = this.resvConfirmInfo.roomData[0].roomNm + "외 " + (resvRoomSize - 1) + "실";
                    } else {
                        resvRoomNm = this.resvConfirmInfo.roomData[0].roomNm;
                    }

                    this.resvConfirmInfo.resvRoomNm = resvRoomNm;
                    this.resvConfirmInfo.resvNop = this.resvConfirmInfo.roomData[0].resvNop;
                    this.resvConfirmInfo.resvDateRange = this.resvConfirmInfo.roomData[0].resvDateRange;
                    this.resvConfirmInfo.delYn = 'N';
                    this.resvConfirmInfo.payType = this.payType;

                    this.$store.dispatch("reservation/resvUpdate", this.resvConfirmInfo)
                        .then((resp) => {
                            setTimeout(() => {

                                let resvUpdateData = resp;
                                let _this = this;

                                if (resvUpdateData.result == 'succeed') {
                                    let tossPayments = TossPayments(_this.clientKey);
                                    var today = new Date();

                                    var year = today.getFullYear();
                                    var month = ('0' + (today.getMonth() + 1)).slice(-2);
                                    var day = ('0' + today.getDate()).slice(-2);

                                    var dateString = year + '-' + month + '-' + day;

                                    switch (_this.payType) {
                                        case "0" : {    // 무통장
                                            this.$store.commit("reservation/CLEAR_RESV_CONFIRM", this.resvConfirmList);
                                            location.href = '/reservation/paymentConfirmTmp?resvId=' + resvUpdateData.message.resvId + '&confirmGubun=0';
                                            break;
                                        }
                                        case "1" : {    //가상계좌
                                            tossPayments.requestPayment('가상계좌', { // 결제 수단 파라미터
                                                // 결제 정보 파라미터
                                                amount: _this.resvConfirmInfo.totalPrice,
                                                orderId: resvUpdateData.message.resvId + "-" + dateString,
                                                orderName: resvRoomNm,
                                                customerName: _this.resvConfirmInfo.payName,
                                                successUrl: 'http://localhost:8080/reservation/paymentConfirmTmp?resvId=' + resvUpdateData.message.resvId + '&confirmGubun=0',
                                                failUrl: 'http://localhost:8080/reservation/paymentConfirmTmp?resvId=' + resvUpdateData.message.resvId + '&confirmGubun=1',
                                                validHours: 24,
                                            }).catch(function (error) {
                                                if (error.code === 'USER_CANCEL') {
                                                    // 취소 이벤트 처리
                                                    alert("결제를 취소하였습니다.");
                                                    _this.resvDelete(resvUpdateData.message.resvId);
                                                }
                                            });
                                            break;
                                        }
                                        case "2" : {    // 카드
                                            tossPayments.requestPayment('카드', { // 결제 수단 파라미터
                                                // 결제 정보 파라미터
                                                amount: _this.resvConfirmInfo.totalPrice,
                                                orderId: resvUpdateData.message.resvId + "-" + dateString,
                                                orderName: resvRoomNm,
                                                customerName: _this.resvConfirmInfo.payName,
                                                successUrl: 'http://localhost:8080/reservation/paymentConfirmTmp?resvId=' + resvRoomNm,
                                                failUrl: 'http://localhost:8080?resvId=' + resvRoomNm,
                                            }).catch(function (error) {
                                                if (error.code === 'USER_CANCEL') {
                                                    // 취소 이벤트 처리
                                                    alert("결제를 취소하였습니다.");
                                                    resvDelete(resvUpdateData.message.resvId);
                                                }
                                            });

                                            break;
                                        }
                                        case "3" : {    //계좌이체
                                            tossPayments.requestPayment('계좌이체', { // 결제 수단 파라미터
                                                // 결제 정보 파라미터
                                                amount: _this.resvConfirmInfo.totalPrice,
                                                orderId: resvUpdateData.message.resvId + "-" + dateString,
                                                orderName: resvRoomNm,
                                                customerName: _this.resvConfirmInfo.payName,
                                                successUrl: 'http://localhost:8080/reservation/paymentConfirmTmp?resvId=' + resvUpdateData.message.resvId + '&confirmGubun=0',
                                                failUrl: 'http://localhost:8080/reservation/paymentConfirmTmp?resvId=' + resvUpdateData.message.resvId + '&confirmGubun=1',
                                                //bank: '농협'
                                            }).catch(function (error) {
                                                if (error.code === 'USER_CANCEL') {

                                                    alert("결제를 취소하였습니다.");
                                                    _this.resvDelete(resvUpdateData.message.resvId);

                                                }
                                            });
                                            break;
                                        }
                                    }

                                } else {
                                    alert(resp.message);
                                }

                            }, 1300)
                        })
                        .catch((err) => {
                        });
                }

            },
            resvDelete(val) {

                return this.$store.dispatch("reservation/resvDelete", {resvId: val})
                    .then((resp) => {

                        this.dialog = false;


                    })
                    .catch((err) => {
                    })
            },
            moveFocus(num, here, next) {
                var str = here.value.length;
                if (str == num)
                    next.focus();
            },
            isSame() {

                var pw = this.resvConfirmInfo.resvPw;
                var confirmPW = this.resvConfirmInfo.resvPwConfirm;
                if (pw.length < 3 || pw.length > 16) {
                    window.alert('비밀번호는 3글자 이상, 16글자 이하만 이용 가능합니다.');
                    document.getElementById('resvPw').value = document.getElementById('resvPwConfirm').value = '';
                    document.getElementById('same').innerHTML = '';
                }
                if (document.getElementById('resvPw').value != '' && document.getElementById('resvPwConfirm').value != '') {
                    if (document.getElementById('resvPw').value == document.getElementById('resvPwConfirm').value) {
                        document.getElementById('same').innerHTML = '비밀번호가 일치합니다.';
                        document.getElementById('same').style.color = 'blue';
                    } else {
                        document.getElementById('same').innerHTML = '비밀번호가 일치하지 않습니다.';
                        document.getElementById('same').style.color = 'red';
                    }
                }
            },
            selectChange(event, item) {
                let addPrice = 0;

                for (let i = 0; this.campItem.length > i; i++) {
                    let item1 = this.campItem[i];
                    if (item1.isCheck) {
                        addPrice += (item1.campQty * item1.campPrice);
                    }

                    if (item.campId === this.campItem[i].campId) {

                        this.campItem[i].campPrices = (item.campQty * item.campPrice);
                        this.campItem[i].isCheck = true;
                    }
                }
                this.resvConfirmInfo.totalPrice = this.resvConfirmInfo.resvPrice + addPrice;

                this.checkChange(null, item);

            },
            selectChange1(event) {
                this.resvConfirmInfo.phone11 = "";
                if (event.target.value === 'direct') {
                    this.isDirectPhone = true

                } else {
                    this.isDirectPhone = false;
                }

            },
            checkPayName(event) {
                if (this.isCheckPayName) {
                    this.resvConfirmInfo.payName = this.resvConfirmInfo.resvName;
                } else {
                    this.resvConfirmInfo.payName = "";
                }

            },
            checkChange(event, item) {

                let addPrice = 0;

                for (let i = 0; this.campItem.length > i; i++) {
                    let item1 = this.campItem[i];
                    if (item1.isCheck) {
                        addPrice += (item1.campQty * item1.campPrice);
                    }
                }

                this.resvConfirmInfo.totalPrice = this.resvConfirmInfo.resvPrice + addPrice;
            },
            init() {
                this.resize()
            },
            input(value) {
                this.$emit('input', value);
                this.resize()
            },
            resize($event) {
                if (!this.autoresize) return;
                this.$textarea.style.height = '1px';
                this.$textarea.style.height = `${this.$textarea.scrollHeight + 12}px`
            },
            input1(value) {
                this.$emit('input', value);
                this.resize1()
            },
            resize1($event) {
                if (!this.autoresize) return;

                this.$textarea1.style.height = '1px';
                this.$textarea1.style.height = `${this.$textarea1.scrollHeight + 12}px`
            },
            autoHyphen(target) {

                this.$nextTick(() => {
                    if (this.resvConfirmInfo.resvPhone != undefined) {

                        this.resvConfirmInfo.resvPhone = this.resvConfirmInfo.resvPhone.replace(/[^0-9]/g, '')
                            .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
                            .replace(/(\-{1,2})$/g, "")

                        target.value = this.resvConfirmInfo.resvPhone

                    }

                })

                /*if(this.resvConfirmInfo.resvPhone != undefined){
                    this.resvConfirmInfo.resvPhone = this.resvConfirmInfo.resvPhone
                        .replace(/[^0-9]/g, '')
                        .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "");
                }*/


            },


        },
        mounted() {

        },
        computed: {
            $textarea() {
                return document.getElementById('textarea')
            },
            $textarea1() {
                return document.getElementById('textarea1')
            }
        },
    }
</script>

<style>
    .disable-events {
        pointer-events: none
    }

    .selectItem {
        border: 1px solid #039BE5;
    }
</style>
